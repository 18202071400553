import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Subheader from '../components/Subheader';

const Impressum = ({ data }) => (
  <Layout
    title={data.datoCmsImpressum.seo && data.datoCmsImpressum.seo.title}
    description={data.datoCmsImpressum.seo && data.datoCmsImpressum.seo.description}
  >
    <div className="common-page">
      <Subheader title="IMPRESSUM" />

      <div className="container">
        <div className="row">
          <div
            className="col"
            dangerouslySetInnerHTML={{
              __html: data.datoCmsImpressum.text
            }}
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default Impressum;

export const query = graphql`
  query datoCmsImpressumQuery {
    datoCmsImpressum {
      text
      seo {
        title
        description
      }
    }
  }
`;
