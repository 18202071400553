import React, { Component } from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';

const closeAccordeon = '/images/icons/close-accordeon.svg';

class Subheader extends Component {
  state = {
    isOpenIntro: true,
    firstClose: false
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  toggleIntro = state => {
    this.setState(() => ({
      isOpenIntro: state
    }));
  };

  handleScroll = () => {
    const { isOpenIntro, firstClose } = this.state;

    if (window.pageYOffset > 50 && isOpenIntro && !firstClose) {
      this.toggleIntro(false);
      this.setState(() => ({
        firstClose: true
      }));
    }
  };

  render() {
    const { title, intro } = this.props;
    const { labelText, labelHashtag, labelLink } = this.props.data.allDatoCmsMainPage.edges[0].node;
    const { isOpenIntro } = this.state;

    return (
      <div className="subheader">
        <div className="subheader__label">
          <div className="container">
            <h1>{title}</h1>
          </div>
          <Link to={`/${labelLink}`}>
            <div className="hashtag">
              {labelText} <span> {labelHashtag} </span>
            </div>
          </Link>

          {intro && (
            <div className="container">
              <div className="subheader__label__arrow">
                {!isOpenIntro && (
                  <img
                    src={closeAccordeon}
                    alt="closeAccordeon"
                    onClick={() => this.toggleIntro(true)}
                  />
                )}
                {isOpenIntro && (
                  <img
                    src={closeAccordeon}
                    alt="closeAccordeon"
                    style={{ transform: 'rotate(180deg)' }}
                    onClick={() => this.toggleIntro(false)}
                  />
                )}
              </div>
            </div>
          )}
        </div>

        {isOpenIntro && intro && (
          <div className="subheader__intro">
            <div className="container">
              <div className="subheader__intro__text" dangerouslySetInnerHTML={{ __html: intro }} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query SubheaderQuery {
        allDatoCmsMainPage {
          edges {
            node {
              labelText
              labelHashtag
              labelLink
            }
          }
        }
      }
    `}
    render={data => <Subheader data={data} {...props} />}
  />
);
